import {
  ServicesConsents,
  UsercentricsFunctionality,
} from "usercentrics-browser-ui";
import {
  UsercentricsEvent,
  UsercentricsServiceName,
  UsercentricsUIOld,
  WindowState,
} from "../types/index.js";
import { isBrowser } from "./utils.js";

export const usercentricsDataProcessingServices = <const>[
  "Promotion",
  "Google Maps",
  "YouTube Video",
];

const getUsercentricsUIOld = (): Promise<UsercentricsUIOld> =>
  new Promise((resolve) => {
    const initializedEventName = "UC_UI_INITIALIZED";

    const { UC_UI } = window as unknown as WindowState;
    if (UC_UI?.isInitialized()) {
      resolve(UC_UI);
      return;
    }

    const onInitializedEvent = () => {
      const { UC_UI } = window as unknown as WindowState;
      UC_UI && resolve(UC_UI);
      window.removeEventListener(initializedEventName, onInitializedEvent);
    };

    window.addEventListener(initializedEventName, onInitializedEvent);
  });

const getUsercentricsUI = (): Promise<UsercentricsFunctionality> =>
  new Promise(async (resolve) => {
    const initializedEventName = "UC_UI_INITIALIZED";

    const __ucCmp = window.__ucCmp;
    if (await __ucCmp?.isInitialized()) {
      resolve(__ucCmp);
      return;
    }

    const onInitializedEvent = () => {
      const __ucCmp = window.__ucCmp;
      __ucCmp && resolve(__ucCmp);
      window.removeEventListener(initializedEventName, onInitializedEvent);
    };

    window.addEventListener(initializedEventName, onInitializedEvent);
  });

export const getUsercentricsServiceIdOld = (
  serviceName: string,
): Promise<string | undefined> =>
  new Promise(async (resolve) => {
    const UC_UI = await getUsercentricsUIOld();
    resolve(
      UC_UI.getServicesBaseInfo().find(({ name }) => name === serviceName)?.id,
    );
  });

export const getUsercentricsServiceConsents = (
  serviceName: string,
): Promise<ServicesConsents | undefined> =>
  new Promise<ServicesConsents | undefined>(async (resolve) => {
    const __ucCmp = await getUsercentricsUI();
    const consentDetails = (await __ucCmp.getConsentDetails()).services;

    const services = Object.entries(consentDetails).find(
      ([, service]) => service.name === serviceName,
    );

    if (services) {
      const [id, service] = services;
      resolve([{ id, consent: service.consent?.given }]);
    }
    resolve(undefined);
  });

export const getUsercentricsConsentOld = (
  serviceName: UsercentricsServiceName,
): Promise<boolean> =>
  new Promise(async (resolve) => {
    if (!isBrowser) {
      resolve(false);
      return;
    }

    const UC_UI = await getUsercentricsUIOld();

    const consentStatus =
      UC_UI.getServicesBaseInfo().find(({ name }) => name === serviceName)
        ?.consent.status ?? false;

    resolve(consentStatus);
  });

export const getUsercentricsConsent = async (
  serviceName: UsercentricsServiceName,
): Promise<boolean> => {
  const UC_UI = window.__ucCmp;
  if (UC_UI) {
    const consentDetails = await UC_UI.getConsentDetails();

    const service = Object.values(consentDetails.services).find(
      (s) => s.name === serviceName,
    );
    if (!service?.consent) return false;

    return service.consent?.given;
  }
  return false;
};

const checkIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (<UsercentricsEvent>event)?.detail?.event === "consent_status";

export const onUsercentricsConsent = (
  serviceName: UsercentricsServiceName,
): Promise<void> =>
  new Promise((resolve) => {
    const eventName = "UsercentricsCustomEvent";
    const onEvent = (event: Event) => {
      const consentIsGiven =
        checkIsUsercentricsEvent(event) && event.detail[serviceName];
      consentIsGiven && resolve();
      consentIsGiven && window.removeEventListener(eventName, onEvent);
    };

    window.addEventListener(eventName, onEvent);
  });
