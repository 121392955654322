import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { getActiveSite } from "../selectors/sites.js";
import {
  ColorScheme,
  Language,
  StoreState,
  UsercentricsServiceName,
  WindowState,
} from "../types/index.js";
import { getUsercentricsServiceConsents } from "../utils/usercentrics.js";
import { getActiveColorScheme, getTranslations } from "../utils/utils.js";

interface Props {
  languageId: Language;
  serviceName: UsercentricsServiceName;
  onConsentGiven: () => void;
}

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const translations = {
  de: {
    title: "Wir brauchen Ihr Einverständnis!",
    text: (
      serviceName: string,
    ) => `Wir benutzen Drittanbieter (${serviceName}), um Inhalte einzubinden.
      Diese können persönliche Daten über Ihre Aktivitäten sammeln.
      Bitte beachten Sie die Details und geben Sie Ihre Einwilligung.`,
    moreInfo: "Mehr Informationen",
    accept: "Akzeptieren",
  },
  en: {
    title: "We need your consent!",
    text: (
      serviceName: string,
    ) => `We rely on third party providers (${serviceName}) to integrate the content.
      They may collect personal information about your activities.
      Please note the details and give your consent.`,
    moreInfo: "More information",
    accept: "Accept",
  },
  it: {
    title: "Abbiamo bisogno del Suo consenso!",
    text: (
      serviceName: string,
    ) => `Per l’integrazione dei contenuti ci avvaliamo di fornitori terzi (${serviceName})
      che possono raccogliere dati personali sulle Sue attività.
      La preghiamo di prendere nota dei dettagli e di dare il Suo consenso.`,
    moreInfo: "Ulteriori informazioni",
    accept: "Accettare/acconsentire",
  },
};

const ModuleConsentBox: FunctionComponent<Props & ReduxProps> = ({
  languageId,
  serviceName,
  scheme,
  onConsentGiven,
}) => {
  const i18n = getTranslations(languageId, translations);

  return (
    <div className="ModuleConsentBox">
      <div
        className={ClassNames(
          "ModuleConsentBox__Modal",
          "TextAlign--center",
          style({
            backgroundColor: scheme.main.background,
            color: scheme.main.text,
          }),
        )}
      >
        <h2 className="ModuleConsentBox__Title">{i18n.title}</h2>
        <p className="ModuleConsentBox__Text">
          {serviceName && i18n.text(serviceName)}
        </p>

        <div className="ButtonGroup ButtonGroup--align-center">
          <button
            className={ClassNames(
              "ModuleConsentBox__Button",
              "ModuleConsentBox__Button--more",
              "Button",
              "Btn--bare",
              style({
                backgroundColor: scheme.secondary.background,
                borderColor: scheme.secondary.background,
                color: scheme.secondary.text,
              }),
            )}
            onClick={() => {
              const { UC_UI } = window as unknown as WindowState;
              UC_UI?.showSecondLayer();
              window.__ucCmp?.showSecondLayer();
            }}
          >
            {i18n.moreInfo}
          </button>
          <button
            type="button"
            className={ClassNames(
              "ModuleConsentBox__Button",
              "ModuleConsentBox__Button--accept",
              "Button",
              "Btn--bare",
              style({
                backgroundColor: scheme.primary.background,
                borderColor: scheme.primary.background,
                color: scheme.primary.text,
              }),
            )}
            onClick={async () => {
              // const idOld = await getUsercentricsServiceIdOld(serviceName);
              // if (!idOld) return;
              // const { UC_UI } = window as unknown as WindowState;
              // UC_UI?.acceptService(idOld);

              console.log(serviceName);
              const id = await getUsercentricsServiceConsents(serviceName);
              if (!id) return;
              window.__ucCmp.updateServicesConsents(id);

              onConsentGiven();
            }}
          >
            {i18n.accept}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  colorSchemes,
  sites,
}): StateProps => ({
  scheme: getActiveColorScheme(colorSchemes, getActiveSite(sites)),
});

const connector = connect(mapStateToProps);

export default connector(ModuleConsentBox);
